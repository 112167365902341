<template>
    <hb-expansion-panel key="reservation_setting" v-if="reservationSettingState">
        <template v-slot:title>Reservation Settings</template>
        <template v-slot:content>
            <hb-form label="Reservation Expiration"
                :description="active === 'Reservation Expiration' || (!editMode && !readMode) ? 'Updates the reservation expiration time' : undefined"
                :required="!readMode" :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
                :start-open="!editMode && !readMode" :hide-actions="!editMode && !readMode" :active.sync="active"
                :empty="!template.reservation_expiration && !readMode" @cancel="handleEmittedCancel"
                @save="handleEmittedSave('reservation_expiration')" :auto-layout="false" full
                :disable-save="disableSave"
                >
                <template v-slot:display>
                    <div>{{ template.reservation_expiration }} {{ template.reservation_expiration > 1 ? 'days' : 'day'
                        }}
                    </div>
                </template>
                <template v-slot:edit>
                    <HbTextField v-model.number="template.reservation_expiration"
                        v-validate="'required|integer|min_value:1'" :clearable="false" :min="1"
                        id="reservation_expiration" name="reservation_expiration" data-vv-scope="form_2"
                        data-vv-as="Reservation Expiration" :error="errors.has('form_2.reservation_expiration')">
                    </HbTextField>
                </template>
            </hb-form>
            <hb-form label="Advance Reservation Period"
                :description="active === 'Advance Reservation Period' || (!editMode && !readMode) ? 'Updates the advance reservation time' : undefined"
                :required="!readMode" :editable="(editMode && isPermissionToEdit) || (!editMode && !readMode)"
                :start-open="!editMode && !readMode" :hide-actions="!editMode && !readMode" :active.sync="active"
                :empty="!template.advance_reservation_period && !readMode" @cancel="handleEmittedCancel"
                @save="handleEmittedSave('advance_reservation_period')" :auto-layout="false" full
                :disable-save="disableSave"
                >
                <template v-slot:display>
                    <div>{{ template.advance_reservation_period }} {{ template.advance_reservation_period > 1 ? 'days' :
                        'day' }}</div>
                </template>
                <template v-slot:edit>
                    <HbTextField v-model.number="template.advance_reservation_period" :min="1"
                        v-validate="'required|integer|min_value:1'" :clearable="false" id="advance_reservation_period"
                        name="advance_reservation_period" data-vv-scope="form_2" data-vv-as="Advance Reservation Period"
                        :error="errors.has('form_2.advance_reservation_period')"></HbTextField>
                </template>
            </hb-form>
        </template>
    </hb-expansion-panel>
</template>

<script>
import api from '../../../assets/api';
import { notificationMixin } from '../../../mixins/notificationMixin';
export default {
    data() {
        return {
            reservationWindowOptions: [],
            reservationWindow: '',

            reservationSettingState: true, // not in use as of now
            reservationSaveBtnDisable: false, // not in use as of now
            template: {
                reservation_expiration: '',
                advance_reservation_period: '',
            },
            active: '',
            editMode: true,
            readMode: false,
            isPermissionToEdit: true,
            responseValue: {},
            disableSave: true            
        };
    },
    async created() {
        await this.getReservationSetting();
    },
    methods: {
        async saveReservationSetting(type) {
            let body = {}
            // Save reservation setting logic
            if (type == 'reservation_expiration') {
                body = { reservation_expiration: { value: this.template.reservation_expiration, id: this.responseValue?.reservation_expiration?.id } }
            } else if (type == 'advance_reservation_period') {
                body = { advance_reservation_period: { value: this.template.advance_reservation_period, id: this.responseValue?.advance_reservation_period?.id } }
            }
            try {
                await api.post(this, api.SETTINGS + `reservation-settings`, body)
                await this.getReservationSetting() // fetch the new data
                let message = type == 'reservation_expiration' ? 'Reservation Expiration' : 'Advance Reservation Period'
                message += ' is updated successfully'
                this.showMessageNotification({
                    type: 'success',
                    description: message
                });
                this.active = ''
            } catch (error) {
                console.log("error during save", error);
                this.showMessageNotification({
                    type: 'error',
                    description: error
                });
            }
        },
        async getReservationSetting() {
            //  get reservation setting
            try {
                let data = await api.get(this, api.SETTINGS + `get-reservations`)
                this.responseValue = data
                this.template.reservation_expiration = data?.reservation_expiration?.value
                this.template.advance_reservation_period = data?.advance_reservation_period?.value
            } catch (error) {
                console.log("error at get reservations", error);
                this.showMessageNotification({
                    type: 'error',
                    description: error
                });
            }
        },
        handleSetActive(label) {
            this.active = label;
        },
        clearActive() {
            this.active = '';
        },
        handleEmittedCancel() {
            this.active = ''
            this.template.reservation_expiration = this.responseValue?.reservation_expiration?.value
            this.template.advance_reservation_period = this.responseValue?.advance_reservation_period?.value
        },
        async handleEmittedSave(type) {
            if (type == 'reservation_expiration' && this.template.reservation_expiration == this.responseValue?.reservation_expiration?.value) {
                this.showMessageNotification({
                    type: 'error',
                    description: 'no change '
                });
                return
            } else if (type == 'advance_reservation_period' && this.template.advance_reservation_period == this.responseValue?.advance_reservation_period?.value) {
                this.showMessageNotification({
                    type: 'error',
                    description: error
                });
                return
            }
            await this.saveReservationSetting(type)

        },
    },
    watch:{
        'template.advance_reservation_period'(){
            this.disableSave = this.template.advance_reservation_period == this.responseValue?.advance_reservation_period?.value
        },
        'template.reservation_expiration'(){
            this.disableSave = this.template.reservation_expiration == this.responseValue?.reservation_expiration?.value
        }
    },
    mixins: [notificationMixin],
};
</script>
